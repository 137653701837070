import React, { useEffect, useRef, useContext, useState } from "react";
import "../styles/UserProfile.css";
import { FaHome, FaSignOutAlt, FaBriefcase, FaCrown, FaCog } from 'react-icons/fa'; // Added FaCrown for premium
import { UserContext } from "../Context/UserContext";
import config from "../services/config";
import { useNavigate } from "react-router-dom";
import apiService from "../services/apiService"; // API Service for fetching plan details

const UserProfile = ({ onClose }) => {
  const { user, setUser } = useContext(UserContext);
  const popupRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [planType, setPlanType] = useState("");
  const [expiryDate, setExpiryDate] = useState(""); // Plan expiry date
  const navigate = useNavigate(); // React Router Hook for navigation

  // **Navigate Functions**
  const goToPortfolios = () => navigate("/portfolios");
  const goToHome = () => navigate("/");
  const goToPremium = () => navigate("/premium"); // Navigate to Premium Page
  const handleSettingsClick = () => navigate("/settings"); // Navigate to Premium Page

  // **Close Popup on Outside Click**
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  // **Fetch User Details and Plan Status**
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
      setIsLoggedIn(true);

      // Fetch Plan Details
      const fetchPlanDetails = async () => {
        try {
          const response = await apiService.fetchData("/api/payments/current-plan");
          if (response.plan !== "Free") {
            setIsPremium(true); // Premium User
            setPlanType(response.plan); // Monthly or Yearly
            setExpiryDate(new Date(response.expiryDate).toLocaleDateString()); // Format Date
          } else {
            setPlanType("Free"); // Free Plan
          }
        } catch (error) {
          console.error("Failed to fetch plan details:", error);
        }
      };

      fetchPlanDetails();
    } else {
      setIsLoggedIn(false);
    }
  }, [setUser]);

  // **Logout Function**
  const handleLogoutClick = () => {
    setUser(null); // Clear user context
    setIsLoggedIn(false); // Update logged-in state
    window.location.href = "/logout"; // Redirect to logout
  };

  // **Google Authentication**
  const handleGoogleAuth = () => {
    window.location.href = `${config.API_BASE_URL}/api/auth/google`;
  };

  return (
    <div className="user-profile-overlay">
      <div className="user-profile-popup" ref={popupRef}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {isLoggedIn ? (
          <>
            {/* Profile Picture */}
            <img
              src={user?.profilePicture || "/images/user-avatar.png"}
              alt="User Avatar"
              className="user-profile-avatar"
              onError={(e) => {
                e.target.src = "/images/user-avatar.png"; // Fallback image
              }}
            />
            <h2 className="user-profile-name">{user?.name || "Guest"}</h2>
            <p className="user-profile-email">{user?.email || "Not logged in"}</p>

            {/* Links Section */}
            <div className="user-profile-links">
              <button className="profile-link" onClick={goToHome}>
                <FaHome className="profile-icon" /> Home
              </button>
              <button className="portfolios-button" onClick={goToPortfolios}>
                <FaBriefcase className="profile-icon" /> Portfolios
              </button>

              <button className="settings-button" onClick={handleSettingsClick}>
                <FaCog className="profile-icon" /> Settings
              </button>

              {/* Premium Option */}
              <button
                className={`premium-button ${isPremium ? "premium-active" : ""}`}
                onClick={goToPremium}
              >
                <FaCrown className="profile-icon" />
                {isPremium
                  ? planType === "Monthly"
                    ? "Upgrade to Yearly Plan"
                    : "Enjoy Your Premium Plan"
                  : "Upgrade to Premium"}
              </button>

              {/* Logout Option */}
              <button className="logout-button" onClick={handleLogoutClick}>
                <FaSignOutAlt className="profile-icon" /> Logout
              </button>
            </div>
          </>
        ) : (
          <>
            <img
              src="/images/user-avatar.png"
              alt="Default Avatar"
              className="user-profile-avatar"
            />
            <h2 className="user-profile-name">Guest</h2>
            <div className="user-profile-links">
              <button className="google-button" onClick={handleGoogleAuth}>
                <img
                  src="/images/google.png"
                  alt="Google Icon"
                  className="google-icon"
                />
                Login with Google
              </button>

              {/* Premium Option */}
              <button
                className={`premium-button ${isPremium ? "premium-active" : ""}`}
                onClick={goToPremium}
              >
                <FaCrown className="profile-icon" />
                {isPremium
                  ? planType === "Monthly"
                    ? "Upgrade to Yearly Plan"
                    : "Enjoy Your Premium Plan"
                  : "Upgrade to Premium"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
