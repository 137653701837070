import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const CallbackHandler = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const location = useLocation();

    useEffect(() => {
        // Check if there's any method to fetch data directly from a server or look for query parameters
        const queryParams = new URLSearchParams(location.search);
        const transactionId = queryParams.get('transactionId');
        const status = queryParams.get('status');
        const signature = queryParams.get('signature');

        // Log or display the data as required
        console.log("Transaction ID:", transactionId);
        console.log("Status:", status);
        console.log("Signature:", signature);

        // Simulating setting data from queryParams if not fetching from an API
        setData({
            transactionId,
            status,
            signature
        });

        setLoading(false);
    }, [location]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Callback Data Received</h1>
            <p>Transaction ID: {data.transactionId}</p>
            <p>Status: {data.status}</p>
            <p>Signature: {data.signature}</p>
        </div>
    );
};

export default CallbackHandler;
