import React, { useEffect, useState, useContext, useRef } from "react";
import "../styles/PremiumPage.css";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import apiService from "../services/apiService"; // API service for requests
import { UserContext } from "../Context/UserContext";
import LoadingBar from "react-top-loading-bar"; // Import the LoadingBar component

const PremiumPage = () => {
  const [currentPlan, setCurrentPlan] = useState(null); // Store current plan details
  const { user } = useContext(UserContext);
  const loadingBarRef = useRef(null); // Create a ref for the loading bar

  // **1. Fetch Current Plan Details**
  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const response = await apiService.fetchData(
          "/api/payments/current-plan"
        );
        setCurrentPlan(response); // Set plan details
      } catch (error) {
        console.error("Failed to fetch plan:", error);
      }
    };

    if (user) {
      fetchCurrentPlan();
    }
  }, [user]);

  const handlePayment = async (planType, price) => {
    if (!user) {
      alert("Please log in or register to subscribe to a plan.");
      return;
    }
    loadingBarRef.current.continuousStart(); // Start the loading bar

    try {
      const response = await apiService.fetchData(
        "/api/payments/create-payment",
        "POST",
        {
          amount: price,
          phone: user.phone, // Assuming the user object has a phone property
          userId: user.id, // Assuming the user object has an id property
          planType,
        }
      );
      if (response.url) {
        window.location.href = response.url; // Redirect to PhonePe payment page
      } else {
        loadingBarRef.current.complete(); // Complete the loading bar on error
        alert("Payment initialization failed:", response.error);
      }
    } catch (error) {
      loadingBarRef.current.complete(); // Complete the loading bar on error
      console.error("Payment Error:", error);
      alert("Error processing payment. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <LoadingBar ref={loadingBarRef} color="#00ff00" height={4} />
      <Helmet>
        <title>Premium Plan | Stock Galaxy</title>
        <meta
          name="description"
          content="Unlock premium features with Stock Galaxy's flexible billing plans—monthly at ₹50 or yearly at ₹500 with a discount!"
        />
      </Helmet>
      <h1>Subscription</h1>
      <div className="premium-container">
        <div className="premium-cards">
          {!user || (user && currentPlan && currentPlan.plan === "Free") ? (
            <>
              <div className="premium-card">
                <h2>Free</h2>
                <ul className="premium-features">
                  <li>✔ 1 Portfolio</li>
                  <li>✔ 5 Stocks in Portfolio</li>
                  <li>✖ No Priority Support</li>
                  <li>✖ Basic Analytics Only</li>
                </ul>
              </div>
              <div className="premium-card">
                <h2>Monthly</h2>
                <p className="price">₹50/month</p>
                <ul className="premium-features">
                  <li>✔ Unlimited Portfolios</li>
                  <li>✔ Unlimited Stocks in Each Portfolio</li>
                  <li>✔ Priority Support</li>
                </ul>
                <button
                  className="subscribe-btn"
                  onClick={() => handlePayment("monthly", 50)}
                >
                  Purchase
                </button>
              </div>
              <div className="premium-card">
                <h2>Yearly</h2>
                <p className="price">
                  ₹500/year <span className="discount">Save ₹100!</span>
                </p>
                <ul className="premium-features">
                  <li>✔ Unlimited Portfolios</li>
                  <li>✔ Unlimited Stocks in Each Portfolio</li>
                  <li>✔ Priority Support</li>
                </ul>
                <button
                  className="subscribe-btn"
                  onClick={() => handlePayment("yearly", 500)}
                >
                  Purchase
                </button>
              </div>
            </>
          ) : (
            user &&
            currentPlan && (
              <>
                <div className="premium-card current-plan">
                  <h2>Current Plan</h2>
                  <p className="price">
                    {`${
                      currentPlan.plan.charAt(0).toUpperCase() +
                      currentPlan.plan.slice(1)
                    } Plan`}
                  </p>
                  <ul className="premium-features">
                    <li>✔ Unlimited Portfolios</li>
                    <li>✔ Unlimited Stocks in Each Portfolio</li>
                    <li>✔ Priority Support</li>
                  </ul>
                  <p className="note">
                    Your plan expires on{" "}
                    {new Date(currentPlan.expiryDate).toDateString()}.
                  </p>
                </div>
                {currentPlan.plan !== "yearly" && (
                  <div className="premium-card">
                    <h2>Yearly</h2>
                    <p className="price">
                      ₹500/year <span className="discount">Save ₹100!</span>
                    </p>
                    <ul className="premium-features">
                      <li>✔ Unlimited Portfolios</li>
                      <li>✔ Unlimited Stocks in Each Portfolio</li>
                      <li>✔ Priority Support</li>
                    </ul>
                    <button
                      className="subscribe-btn"
                      onClick={() => handlePayment("yearly", 500)}
                    >
                      Purchase
                    </button>
                  </div>
                )}
              </>
            )
          )}
        </div>

        <div className="policy-links">
          <Link to="/privacy-policy" className="policy-link">
            Privacy Policy
          </Link>
          <span> | </span>
          <Link to="/cancellation-refund-policy" className="policy-link">
            Cancellation or Refund Policy
          </Link>
          <span> | </span>
          <Link to="/terms-and-conditions" className="policy-link">
            Terms & Condition
          </Link>
          <span> | </span>
          <Link to="/contact-us" className="policy-link">
            Contact Us
          </Link>
        </div>
      </div>
    </>
  );
};

export default PremiumPage;
