// src/pages/ShippingPolicy.js
import React from "react";
import GeneralFooter from "../components/GeneralFooter";
import Navbar from "../components/Navbar";
import "../styles/StaticPages.css";

function ShippingPolicy() {
  return (
    <div>
      <Navbar />
      <div className="content">
        <h1>Shipping Policy</h1>
        <p>
          Our shipping policy ensures timely delivery of your orders with accurate tracking information. If you have any questions, contact
          <a href="mailto:baksikumaresh@gmail.com"> baksikumaresh@gmail.com</a>.
        </p>
      </div>
      <GeneralFooter />
    </div>
  );
}

export default ShippingPolicy;
