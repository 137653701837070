// src/pages/CancellationRefundPolicy.js
import React from "react";
import GeneralFooter from "../components/GeneralFooter";
import Navbar from "../components/Navbar";
import "../styles/StaticPages.css";

function CancellationRefundPolicy() {
  return (
    <div>
      <Navbar />
      <div className="content">
        <h1>Cancellation and Refund Policy</h1>
        <p style={{ textAlign: "center" }}>
          All Sales are final there will be no refund provided under any circumstances.
        </p>
      </div>
      <GeneralFooter />
    </div>
  );
}

export default CancellationRefundPolicy;
